import React, { useState, useEffect } from 'react';

function Home() {
  const [serverCount, setServerCount] = useState('Loading...');

  useEffect(() => {
    const fetchServerCount = async () => {
      try {
        const response = await fetch('https://discord.com/api/v9/users/@me/guilds', {
          headers: {
            'Authorization': `Bot MTA1Mjg0MjUzNzA3NDgyMzE4OA.GEOq4T.rZX-bG5VqDfLn10wM0Rx6K7WPwaVf29fR3FZ1k`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setServerCount(data.length);
        } else {
          console.error('Failed to fetch server count:', response.status);
          setServerCount('Error');
        }
      } catch (error) {
        console.error('Error fetching server count:', error);
        setServerCount('Error');
      }
    };

    fetchServerCount();
  }, []);

  return (
    <div>
      {/* <div className="flex topInfo" id="info">
        <p>Stibot solo is down for unknown period of time. And stibot team has been reworked all data was erased for data recovery join <a href="https://discord.gg/t8TCT8xeCS">discord server</a> to learn more.</p>
      </div> */}

      <header className="flex hero">
        <h1 className="textAlign">Stibot simplest<br /><span className="frame">Task Management</span> bot</h1>
        <div className="ic-mouse-scroll"></div>
      </header>

      <hr style={{ marginTop: '15vh', marginBottom: '5vh' }} />

      <div className="flex">
        <div className="flex nav">
          <a className="home flex" href="/">Home</a>
          <a href="/privacy-policy">Privacy policy</a>
          <a href="/TermsofService">Terms of Service</a>
          <a href="https://discord.com/api/oauth2/authorize?client_id=1052842537074823188&permissions=139653901393&scope=bot">Invite</a>
          <a href="https://discord.gg/t8TCT8xeCS">Support</a>
          <a className="login flex" href="/panel">Login</a>
        </div>
      </div>

      <div className="flex features">
        <div>
          <h1>Table Management</h1>
          <p>Easily create, manage, and organize your projects inside tables to keep track of all your tasks.</p>
        </div>
        <img className="image" src="/Tables.png" alt="Tables" />
      </div>

      <div className="flex features">
        <img className="image" src="/Tasks.png" alt="Tasks" />
        <div>
          <h1>Task Management</h1>
          <p>Add, list, delete, clone, and edit tasks effortlessly with simple commands.</p>
        </div>
      </div>

      <div className="flex features">
        <div>
          <h1>Multilingual Support</h1>
          <p>Manage your projects seamlessly in English, Polish, German, and French.</p>
        </div>
        <img className="image" src="/Multilingual.png" alt="Multilingual" />
      </div>

      <div className="flex features">
        <img className="image" src="/Perm.png" alt="Permission" />
        <div>
          <h1>Permission Management</h1>
          <p>Easily set and manage permissions for different roles, ensuring smooth access control and collaboration within your Discord community.</p>
        </div>
      </div>

      <hr style={{ marginTop: '15vh', marginBottom: '15vh' }} />

      <div className="flex textAlign column trust">
        <h1>Trusted us</h1>
        <p>Over {serverCount} people have trusted us. Our dream is for you to try out our bot and assist in improving it.</p>
      </div>

      <hr style={{ marginTop: '10vh', marginBottom: '10vh' }} />

      <footer className="flex column">
        <h1>Let's turn<br /><span className="text-gradient">dreams</span> into <span className="text-gradient">reality!</span></h1>
        <hr style={{ marginTop: '5vh', marginBottom: '5vh', borderColor: '#454545' }} />
        <a style={{ fontSize: '1rem', color: 'var(--lighter)', marginBottom: '5vh' }} href="https://loskubalos.eu.org">© Loskubalos 2024</a>
      </footer>
    </div>
  );
}

export default Home;
